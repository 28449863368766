html {
  background-color: black;
}

@keyframes slideInFromBottom {
  0% {
    transform: translatey(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.mywork {
  background-image: url("../images/sunset.jpg");
  background-size: 100% 100%;
  height: 1360px;
}

.work {
  margin-top: 0%;
}

.workTitle {
  font-family: josefin, serif;
  text-align: center;
  font-size: 65px;
  margin-bottom: 0%;
}

.allWork {
  padding-left: 200px;
  padding-right: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.projects {
  -webkit-animation: 1s ease-out 0s 1 slideInFromBottom;
  -moz-animation: 1s ease-out 0s 1 slideInFromBottom;
  -o-animation: 1s ease-out 0s 1 slideInFromBottom;
  animation: 1s ease-out 0s 1 slideInFromBottom;
  width: 240px;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 20px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-around;
  /* width: 240px; */
}

.projectTitle {
  font-family: seg;
  margin-top: 5px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 0%;
}

.projectComponents {
  font-size: 12px;
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.projectComponent {
  text-align: center;
  margin: 5px;
  background-color: lightsteelblue;
  padding: 8px;
  border-radius: 15px;
}

.projectLogos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 10px;
}

.singleLogo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 10px;
}

.projectDescription {
  text-align: center;
  margin: 10px;
}

.projectImage {
  height: 140px;
  width: 240px;
}

.iosImage {
  height: 140px;
  width: 160px;
}

.androidImage {
  height: 140px;
  width: 90px;
}

.projectLogo {
  width: 50px;
  height: 50px;
}

.singleProjectLogo {
  width: 50px;
  height: 50px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  .mywork {
    background-image: url("../images/mobile_sunset.jpg");
    background-size: 100% 100%;
    height: 812px;
  }

  .work {
    color: darkgray;
    margin-top: 0%;
    margin-bottom: 0%;
    padding-top: 10px;
    font-size: 40px;
  }

  .allWork {
    padding-left: 200px;
    padding-right: 200px;
    display: inline;
  }

  .projects:nth-child(odd) {
    -webkit-animation: 1s ease-out 0s 1 slideInFromLeft;
    -moz-animation: 1s ease-out 0s 1 slideInFromLeft;
    -o-animation: 1s ease-out 0s 1 slideInFromLeft;
    animation: 1s ease-out 0s 1 slideInFromLeft;
  }
  .projects:nth-child(even) {
    -webkit-animation: 1s ease-out 0s 1 slideInFromRight;
    -moz-animation: 1s ease-out 0s 1 slideInFromRight;
    -o-animation: 1s ease-out 0s 1 slideInFromRight;
    animation: 1s ease-out 0s 1 slideInFromRight;
  }

  .projects {
    width: auto;
    background-color: rgba(255, 255, 255, 0.8);
    margin: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: unset;
  }

  .projectImage {
    height: 80px;
    width: 140px;
    margin: 0%;
  }

  .projectDetail {
    margin-right: 35px;
  }

  .androidImage {
    height: 90px;
    width: 50px;
    padding-left: 40px;
    padding-right: 50px;
    padding-bottom: 20px;
    margin: 0%;
  }

  .iosImage {
    height: 95px;
    width: 90px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .projectTitle {
    margin-top: 5px;
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0%;
  }

  .projectDescription {
    font-size: 10px;
    text-align: left;
    margin: 0px;
  }

  .projectComponents {
    font-size: 6px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 0%;
    justify-content: left;
  }

  .projectComponent {
    text-align: center;
    margin-top: 5px;
    margin-right: 5px;
    background-color: lightsteelblue;
    padding: 4px;
    border-radius: 15px;
  }

  .projectLogos {
    display: flex;
    justify-content: space-evenly;
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .singleLogo {
    display: flex;
    padding-top: 5px;
    padding-bottom: 10px;
    justify-content: unset;
  }

  .singleProjectLogo {
    padding-top: 0%;
    padding-left: 35px;
    height: 30px;
    width: 30px;
  }

  .projectLogo {
    height: 30px;
    width: 30px;
  }
}
