html {
  background-color: black;
}

.home {
  background-image: url("../images/sunset.jpg");
  background-size: 100% 100%;
  /* opacity: 0.50; */
  height: 1200px;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  width: 40%;
}

.description {
  margin-top: -35px;
  font-size: 50px;
  font-weight: lighter;
  text-align: center;
}

.descriptionName {
  /* font-family: "Lato", sans-serif; */
}

.aboutMe {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  border-radius: 10px;
  padding: 20px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.aboutmeHeader {
  font-size: 25px;
  font-weight: 600;
}

.aboutMeDetails {
  font-size: 18px;
}

.buttonLocation {
  display: flex;
  justify-content: center;
}

.button {
  display: inline-flex;
  height: 40px;
  width: 150px;
  border: 2px solid #bfc0c0;
  margin: 20px 20px 50px 20px;
  color: #bfc0c0;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.8em;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

a {
  color: #bfc0c0;
  text-decoration: none;
  letter-spacing: 1px;
}

#btn {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#btn a {
  position: relative;
  transition: all 0.45s ease-Out;
}

#circle {
  width: 0%;
  height: 0%;
  opacity: 0;
  line-height: 40px;
  border-radius: 50%;
  background: #bfc0c0;
  position: absolute;
  transition: all 0.5s ease-Out;
  top: 20px;
  left: 70px;
}

#btn:hover #circle {
  width: 200%;
  height: 500%;
  opacity: 1;
  top: -70px;
  left: -70px;
}

#btn:hover a {
  color: #2d3142;
}

.socials {
  display: flex;
  justify-content: center;
}

.social {
  height: 60px;
  width: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  .home {
    background-image: url("../images/sunset.jpg");
    background-size: 100% 100%;
    /* opacity: 0.50; */
    height: 812px;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 75%;
  }

  .description {
    margin-top: -5px;
    font-size: 26px;
  }

  .aboutMe {
    width: 70%;
    margin-top: 45px;
    /* margin-bottom: 25px; */
  }

  .aboutmeHeader {
    font-size: 15px;
    font-weight: 500;
  }

  .aboutMeDetails {
    font-size: 10px;
  }

  .buttonLocation {
  }

  .social {
    height: 35px;
    width: 35px;
  }
}
